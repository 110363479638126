<template>
  <div class="order-detail-page">
    <h2>供应商订单 - 详情</h2>
    <div v-loading="loading">
      <!-- 发起人信息 -->
      <el-card class="detail-card" v-if="orderDetail.applicant_info && orderDetail.applicant_info.length > 0">
        <div slot="header" class="card-header">
          <span>申请人</span>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">申请人：</span>
              <span class="value">{{ orderDetail.applicant_info[0]?.applicant_name || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">部门：</span>
              <span class="value">{{ orderDetail.applicant_info[0]?.department || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">岗位：</span>
              <span class="value">{{ orderDetail.applicant_info[0]?.position || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">人员编号：</span>
              <span class="value">{{ orderDetail.applicant_info[0]?.employee_number || "-" }}</span>
            </div>
          </el-col>
        </el-row>
      </el-card>

      <!-- 主表信息 -->
      <el-card class="detail-card">
        <div slot="header" class="card-header">
          <span>订单信息</span>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">订单编号：</span>
              <span class="value">{{ orderDetail.main_info?.order_number || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">区域：</span>
              <span class="value">{{ orderDetail.main_info?.region || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">订单来源：</span>
              <span class="value">{{ orderDetail.main_info?.order_source || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">订单类型：</span>
              <span class="value">{{ orderDetail.main_info?.order_type || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">付款状况：</span>
              <span class="value">{{ orderDetail.main_info?.payment_status || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">执行日期：</span>
              <span class="value">{{ orderDetail.main_info?.settlement_start_time || "-" }} ~ {{ orderDetail.main_info?.settlement_end_time || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">合作内容：</span>
              <span class="value">{{ orderDetail.main_info?.cooperation_content || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">备注：</span>
              <span class="value">{{ orderDetail.main_info?.remarks || "-" }}</span>
            </div>
          </el-col>
        </el-row>
      </el-card>

      <!-- 客户信息 -->
      <el-card class="detail-card">
        <div slot="header" class="card-header">
          <span>客户信息</span>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">客户名称：</span>
              <span class="value">{{ orderDetail.main_info?.customer_name || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">品牌：</span>
              <span class="value">{{ orderDetail.main_info?.brand || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">行业：</span>
              <span class="value">{{ orderDetail.main_info?.industry || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">合同状况：</span>
              <span class="value">{{ orderDetail.main_info?.contract_status || "-" }}</span>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <!-- 供应商信息 -->
      <el-card class="detail-card">
        <div slot="header" class="card-header">
          <span>供应商信息</span>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">供应商名称：</span>
              <span class="value">{{ orderDetail.main_info?.supplier_name || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">政策编号：</span>
              <span class="value">{{ orderDetail.main_info?.policy_number || "-" }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="info-item">
              <span class="label">政策简称：</span>
              <span class="value">{{ orderDetail.main_info?.policy_short_name || "-" }}</span>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <!-- 合作达人明细 -->
      <el-card class="detail-card" v-if="orderDetail.kol_details && orderDetail.kol_details.length > 0">
        <div slot="header" class="card-header">
          <span>合作达人明细</span>
        </div>
        <div class="table-responsive">
          <el-table :data="orderDetail.kol_details" border style="width: 100%" :header-cell-style="{ background: '#f5f7fa', color: '#606266' }" size="small">
            <el-table-column prop="sequence_number" label="序号" min-width="60"></el-table-column>
            <el-table-column prop="platform" label="平台" min-width="80"></el-table-column>
            <el-table-column prop="kol_type" label="达人类型" min-width="100" show-overflow-tooltip></el-table-column>
            <el-table-column prop="nickname" label="昵称" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="platform_id" label="平台ID" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="followers_count" label="粉丝量" min-width="100">
              <template slot-scope="scope">
                {{ formatNumber(scope.row.followers_count) }}
              </template>
            </el-table-column>
            <el-table-column prop="homepage_url" label="主页URL" min-width="120" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-link v-if="scope.row.homepage_url" type="primary" :href="scope.row.homepage_url" target="_blank">访问主页</el-link>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="estimated_plays" label="预估播放量" min-width="100">
              <template slot-scope="scope">
                {{ formatNumber(scope.row.estimated_plays) }}
              </template>
            </el-table-column>
            <el-table-column prop="estimated_interactions" label="预估互动量" min-width="100">
              <template slot-scope="scope">
                {{ formatNumber(scope.row.estimated_interactions) }}
              </template>
            </el-table-column>
            <el-table-column prop="cooperation_form_level1" label="合作形式" min-width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="cooperation_form2" label="自定义形式" min-width="120" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.cooperation_form_level1 === '其他'">{{ scope.row.cooperation_form2 }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="base_price" label="合作裸价" min-width="100">
              <template slot-scope="scope">
                {{ formatAmount(scope.row.base_price) }}
              </template>
            </el-table-column>
            <el-table-column prop="order_cost" label="下单费用" min-width="100">
              <template slot-scope="scope">
                {{ formatAmount(scope.row.order_cost) }}
              </template>
            </el-table-column>
            <el-table-column prop="authorization_cost" label="授权费用" min-width="100">
              <template slot-scope="scope">
                {{ formatAmount(scope.row.authorization_cost) }}
              </template>
            </el-table-column>
            <el-table-column prop="start_time" label="开始时间" min-width="120"></el-table-column>
            <el-table-column prop="end_time" label="结束时间" min-width="120"></el-table-column>
            <el-table-column prop="rebate_percentage" label="返点比例" min-width="100">
              <template slot-scope="scope">
                {{ formatPercentage(scope.row.rebate_percentage) }}
              </template>
            </el-table-column>
            <el-table-column prop="rebate_amount" label="返点金额" min-width="120">
              <template slot-scope="scope">
                {{ formatAmount(scope.row.rebate_amount) }}
              </template>
            </el-table-column>
            <el-table-column prop="rebate_settlement_form" label="返点结算形式" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="remarks" label="备注" min-width="150" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </el-card>

      <!-- 业务订单 -->
      <el-card class="detail-card" v-if="orderDetail.business_orders && orderDetail.business_orders.length > 0">
        <div slot="header" class="card-header">
          <span>业务订单</span>
        </div>
        <div class="table-responsive">
          <el-table :data="orderDetail.business_orders" border style="width: 100%" :header-cell-style="{ background: '#f5f7fa', color: '#606266' }" size="small">
            <el-table-column prop="sequence_number" label="序号" min-width="80"></el-table-column>
            <el-table-column prop="business_application_number" label="业务申请单号" min-width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="supplier_product" label="供应商产品" min-width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="recharge_amount" label="充值金额" min-width="120">
              <template slot-scope="scope">
                {{ formatAmount(scope.row.recharge_amount) }}
              </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注" min-width="150" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </el-card>

      <!-- 付款信息 -->
      <el-card class="detail-card" v-if="orderDetail.main_info">
        <div slot="header" class="card-header">
          <span>付款信息</span>
        </div>
        <div class="table-responsive">
          <el-table :data="[orderDetail.main_info]" border style="width: 100%" :header-cell-style="{ background: '#f5f7fa', color: '#606266' }" size="small">
            <el-table-column prop="payment_time" label="付款时间" min-width="180"></el-table-column>
            <el-table-column prop="payment_status" label="付款状况" min-width="180"></el-table-column>
            <el-table-column prop="payment_amount" label="付款金额" min-width="120">
              <template slot-scope="scope">
                {{ formatAmount(scope.row.payment_amount) }}
              </template>
            </el-table-column>
            <el-table-column prop="payment_ratio" label="付款比例" min-width="120">
              <template slot-scope="scope">
                {{ formatPaymentRatio(scope.row.payment_ratio) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getSupplierOrderDetail } from "@/api/index";

export default {
  name: "OrderDetail",
  data () {
    return {
      orderId: null,
      loading: false,
      orderDetail: {
        main_info: {},
        business_orders: [],
        payment_records: [],
        kol_details: [],
        applicant_info: [],
      },
    };
  },
  created () {
    // 从路由参数中获取订单ID
    this.orderId = this.$route.query.order_id;
    if (this.orderId) {
      this.fetchOrderDetail();
    } else {
      this.$message.error("订单ID不能为空");
    }
  },
  methods: {
    // 获取订单详情
    async fetchOrderDetail () {
      this.loading = true;
      try {
        const res = await getSupplierOrderDetail(this.orderId);
        this.orderDetail = res.data || {
          main_info: {},
          business_orders: [],
          payment_records: [],
          kol_details: [],
          applicant_info: [],
        };
      } catch (error) {
        console.error("获取订单详情失败:", error);
        this.$message.error("获取订单详情失败");
      } finally {
        this.loading = false;
      }
    },
    // 格式化金额
    formatAmount (amount) {
      if (amount === null || amount === undefined) return "-";
      return parseFloat(amount).toLocaleString("zh-CN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    // 格式化百分比
    formatPercentage (value) {
      if (value === null || value === undefined) return "-";
      return (parseFloat(value)).toFixed(2) + "%";
    },
    // 付款比例格式化百分比
    formatPaymentRatio (value) {
      if (value === null || value === undefined) return "-";
      return (parseFloat(value)).toFixed(2) * 100 + "%";
    },
    // 格式化数字（用于粉丝量、播放量等）
    formatNumber (value) {
      if (value === null || value === undefined) return "-";

      // 将数字转换为字符串
      const num = parseFloat(value);

      // 如果数字大于等于10000，则以万为单位显示
      if (num >= 10000) {
        return (num / 10000).toFixed(1) + "w";
      }
      return num.toLocaleString("zh-CN");
    },
  },
};
</script>

<style scoped>
.order-detail-page {
  padding: 20px;
  min-height: calc(100vh - 84px);
}

h2 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #303133;
}

.detail-card {
  margin-bottom: 20px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  transition: all 0.3s;
  box-shadow: none;
}

.detail-card:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header span {
  font-weight: 600;
  font-size: 15px;
  color: #303133;
}

.info-item {
  margin-bottom: 15px;
  font-size: 14px;
  display: flex;
  line-height: 1.5;
}

.label {
  font-weight: 500;
  color: #606266;
  flex-shrink: 0;
  margin-right: 8px;
  min-width: 80px;
}

.value {
  color: #303133;
  word-break: break-all;
  flex: 1;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .order-detail-page {
    padding: 10px;
  }

  .info-item {
    flex-direction: column;
  }

  .label {
    margin-bottom: 5px;
  }

  h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .card-header span {
    font-size: 14px;
  }

  .info-item {
    font-size: 13px;
  }
}
</style>
